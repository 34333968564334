import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import Shop from "../components/Shop/Shop";
import useCategories from "../hooks/useCategories";
import useProducts from "../hooks/useProducts";

const imgStyle = {
  width: "100%",
  height: 300,
};

const ShopPage = ({ location }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  useEffect(async () => {
    setProducts(await useProducts());
    setCategories(await useCategories());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedCategoryHandle = params.get("phan-loai");
    if (categories.length > 0) {
      setSelectedCategory(
        categories.find(
          ({ category_handle }) => category_handle === selectedCategoryHandle
        )
      );
    }
  }, [categories, location]);
  return (
    <Layout title="Cửa hàng">
      <Shop
        products={products}
        categories={categories}
        selectedCategory={selectedCategory}
      />
      <StaticImage
        placeholder="blurred"
        alt="Zn Audio Vinyl"
        style={imgStyle}
        src="../images/vinylbw.jpg"
      />
    </Layout>
  );
};

export default ShopPage;
